import React from "react"

const ClientsIcon = (props) => {
    return (
    <svg width={70} height={70} viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle 
            cx="39.5"
            cy="39.5" 
            r="39.5" 
            fill="#041025"
        />
        <path 
            d="M30.8533 37.0125H29.5589C27.7925 36.9487 26.0319 37.2485 24.3863 37.8935C22.7406 38.5384 21.2451 39.5147 19.9925 40.7617L19.6354 41.1783V53.4971H25.7055V46.5045L26.5238 45.5821L26.8958 45.1507C28.8327 43.1608 31.2442 41.6965 33.9032 40.8956C32.5719 39.8826 31.522 38.5459 30.8533 37.0125Z" 
            fill="#F5F5F5"
        />
        <path 
            d="M59.6266 40.717C58.374 39.47 56.8785 38.4937 55.2328 37.8488C53.5872 37.2039 51.8266 36.904 50.0602 36.9678C49.5185 36.9694 48.9772 36.9992 48.4385 37.0571C47.7573 38.4956 46.736 39.7467 45.463 40.7022C48.3016 41.4875 50.8731 43.0304 52.9019 45.1655L53.2738 45.5821L54.0772 46.5045V53.5119H59.939V41.1336L59.6266 40.717Z" 
            fill="#F5F5F5"
        />
        <path 
            d="M29.5143 34.1115H29.9756C29.7613 32.2715 30.0841 30.4088 30.9052 28.7482C31.7262 27.0877 33.0103 25.7002 34.6025 24.7534C34.0254 23.8717 33.2291 23.155 32.2917 22.6734C31.3544 22.1919 30.308 21.9621 29.2551 22.0064C28.2022 22.0508 27.1789 22.3679 26.2853 22.9266C25.3918 23.4852 24.6586 24.2664 24.1577 25.1936C23.6568 26.1207 23.4052 27.1621 23.4277 28.2157C23.4501 29.2693 23.7458 30.299 24.2858 31.204C24.8258 32.109 25.5915 32.8582 26.5081 33.3783C27.4246 33.8984 28.4605 34.1716 29.5143 34.1711V34.1115Z" 
            fill="#F5F5F5"
        />
        <path 
            d="M49.3459 32.9957C49.364 33.3376 49.364 33.6803 49.3459 34.0223C49.6314 34.0675 49.9198 34.0924 50.2088 34.0966H50.4915C51.5407 34.0407 52.5575 33.714 53.443 33.1485C54.3285 32.5829 55.0524 31.7976 55.5444 30.8692C56.0363 29.9408 56.2794 28.9008 56.2501 27.8506C56.2208 26.8003 55.9201 25.7755 55.3772 24.8759C54.8342 23.9764 54.0676 23.2327 53.152 22.7174C52.2364 22.2021 51.2029 21.9326 50.1522 21.9353C49.1015 21.9379 48.0694 22.2126 47.1564 22.7325C46.2433 23.2524 45.4805 23.9999 44.9421 24.9022C46.2887 25.7814 47.3959 26.9811 48.1645 28.3936C48.9331 29.8062 49.3391 31.3875 49.3459 32.9957Z" 
            fill="#F5F5F5"
        />
        <path 
            d="M39.5864 39.6609C43.2593 39.6609 46.2368 36.6834 46.2368 33.0105C46.2368 29.3376 43.2593 26.3601 39.5864 26.3601C35.9135 26.3601 32.936 29.3376 32.936 33.0105C32.936 36.6834 35.9135 39.6609 39.5864 39.6609Z" 
            fill="#F5F5F5"
        />
        <path 
            d="M39.9434 43.2016C38.0004 43.1232 36.0617 43.4386 34.2438 44.1289C32.4259 44.8193 30.7665 45.8702 29.3654 47.2186L28.9935 47.6351V57.0527C28.9993 57.3594 29.0654 57.662 29.1882 57.9432C29.311 58.2244 29.488 58.4786 29.709 58.6914C29.9301 58.9041 30.1909 59.0712 30.4765 59.1832C30.7622 59.2951 31.0671 59.3497 31.3739 59.3438H48.4682C48.775 59.3497 49.0799 59.2951 49.3655 59.1832C49.6512 59.0712 49.912 58.9041 50.1331 58.6914C50.3541 58.4786 50.5311 58.2244 50.6539 57.9432C50.7766 57.662 50.8428 57.3594 50.8486 57.0527V47.6649L50.4916 47.2186C49.0995 45.8661 47.4459 44.8121 45.632 44.1214C43.8182 43.4307 41.8824 43.1177 39.9434 43.2016Z" 
            fill="#F5F5F5"
        />
    </svg>
    )
}

export default ClientsIcon